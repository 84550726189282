<template>
  <div class="manager">
    <div class="manager__top">
      <div class="manager__sidebar">
        <div class="manager__sidebar-audio-item">AUDIO ITEM</div>
        <div class="manager__sidebar-audio-item">AUDIO ITEM</div>
        <div class="manager__sidebar-audio-item">AUDIO ITEM</div>
        <div class="manager__sidebar-audio-item">AUDIO ITEM</div>
        <div class="manager__sidebar-audio-item">AUDIO ITEM</div>
        <div class="manager__sidebar-audio-item">AUDIO ITEM</div>
        <div class="manager__sidebar-audio-item">AUDIO ITEM</div>
      </div>
      <div class="manager__main">
        <div class="manager__main-header">
          <i class="manager__main-header-item el-icon-back" @click="backToDashboard"></i>
          <div>RIGHT ITEM</div>
        </div>
        <div class="manager__main-upload-wrapper">
          <div class="manager__main-upload">
            <label style="margin: 25px" for="upload_input"><span>Upload</span></label>
            <input id="upload_input" class="" type="file" accept="audio/*" @click="handleUpload()">
          </div>

        </div>
      </div>
    </div>
    <div class="manager__bottom">
      <div class="manager__bottom-player">
        <span>Track:</span>
        <h3 class="player_display" style="margin: 0 15px"></h3>
        <audio class="manager__bottom-audio" controls>
          <track id=player-chords kind=captions srclang=en>
        </audio>
      </div>
      <div class="manager__bottom-tempo">
        <label class=tempo_label for=tempo_input style="margin: 0 15px">Tempo</label>
        <input class="manager__bottom-slider" id=tempo_input type=range value=1 min=0.5 max=2 step=0.025 @click="handleAudioTempo">
        <span class=tempo_display style="margin: 0 15px"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioManager',
  data() {
    return {
      fileList:[]
    }
  },
  methods: {
    backToDashboard() {
      this.$router.push({name : 'Dashboard'})
    },
  uploadFile(e) {
    const player = document.querySelector('.manager__bottom-audio');
    const playerDisplay = document.querySelector('.player_display');
    const target = e.currentTarget;
    const file = target.files[0];
    let reader;

    if (target.files && file) {
      reader = new FileReader();
      reader.onload = function (e) {
        player.setAttribute('src', e.target.result);
        playerDisplay.textContent = file.name;
        // player.play(); // auto play on load
      }
      reader.readAsDataURL(file);
    }
  },
   handleUpload() {
     const uploadInput = document.getElementById('upload_input');
     uploadInput.addEventListener('change', this.uploadFile, false);
   },
   changeAudioTempo() {
     const player = document.querySelector('.manager__bottom-audio');
     const tempoDisplay = document.querySelector('.tempo_display');
     const tempoInput = document.getElementById('tempo_input');
     player.playbackRate = tempoInput.value;
     tempoDisplay.textContent = tempoInput.value;
   },
    handleAudioTempo() {
      const tempoInput = document.getElementById('tempo_input');
      tempoInput.addEventListener("change", this.changeAudioTempo, false);
      const tempoDisplay = document.querySelector('.tempo_display');
      tempoDisplay.textContent = tempoInput.value;
    }
  }
}
</script>

<style lang="scss">
.manager{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__top{
    display: flex;
    flex: 1;
  }

  &__sidebar{
    display: flex;
    flex-direction: column;
    background-color: #05445E;
    color: #F6F7F8;
    padding: 10px;
    margin: 20px 10px;
    flex: 1;
    border-radius: 8px;
   }

  &__sidebar-audio-item{
    margin: 20px 0;
  }

  &__main{
    display: flex;
    flex-direction: column;
    flex: 8;
  }

  &__main-header{
    border-radius: 8px;
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 10px;
    background-color: #05445E;
    color: #F6F7F8;
  }
  &__main-header-item {
    display: flex;
    margin: 0 30px;
    border-radius: 50%;
    transform: scale(1.3);
    padding: 2px;
    border: 1px solid whitesmoke;
    cursor: pointer;
  }

  &__main-upload-wrapper{
    margin: auto;

  }

  &__main-upload{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed grey;
    width: 400px;
    height: 300px;
  }

  &__bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin:  20px 10px;
    padding: 20px;
    background-color: #05445E;
    color: #F6F7F8;
  }

  &__bottom-item{
    margin: 0 25px;
  }

  &__bottom-audio{
    width: 700px;
  }


  &__bottom-player{
  display: flex;
    align-items: center;
    justify-content: center;

  }

  &__bottom-tempo {
    margin: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bottom-slider {
    width: 250px;
  }
}

</style>